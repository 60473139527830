<template>
    <div class="NotFoundContainer">
        <h1>{{$store.state.notfoundtext}}</h1>
        <video id="vid" playsinline autoplay muted loop>
            <source src="./../assets/404.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</template>

<style lang="sass">
.NotFoundContainer
    width: 100vw
    height: 100vh
    position: absolute
    left: 0
    top: 0
    background-color: black
    h1
        position: relative
        top: 20vh
        margin-bottom: 100px
        @media (max-width: 600px)
            font-size: 25px
    video
        @media (max-width: 1100px)
            width: 100vw
            height: auto
            margin-top: 100px
        @media (min-width: 1100px)
            width: 60vw
            height: auto
</style>

<script>
export default {
    //VueMeta
    metaInfo: {
        title: 'Not Found ',
        meta: [
            {
                //vmid: 'description',
                name: 'description',
                content:
                    'This is the 404 page. Hurray!'
            }
        ]
    },
    mounted() {
    document.body.style.height = "auto"
    window.scrollTo(0, 0)
    },
  
    destroyed() {
        document.body.style.height = "1000vh"
    }
}
</script>